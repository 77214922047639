

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none; /* Allows users to interact with elements beneath the overlay */
  z-index: 9999; /* Adjust the z-index as needed */
}